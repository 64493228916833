import React from 'react'
import { getServerSideProps } from '../../lib/misc/generic-get-server-side-props'
import { Login } from '../../modules/auth/login'
import { PageWrapper } from '../../modules/page-wrapper'
import { Page } from '../../types/page.types'
import styles from './login.module.scss'

const LoginPage: React.FC<Page> = (props) => (
  <PageWrapper className={styles.page} {...props}>
    <Login />
  </PageWrapper>
)

export default LoginPage

export { getServerSideProps }

import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classNames from 'classnames'
import Link from 'next/link'
import { FC, ReactElement } from 'react'
import { Button } from '../../button'
import styles from './header-auth.module.scss'

interface HeaderAuthProps {
  className?: string
  isSignIn: boolean
  toggleSignIn(): void
}

export const HeaderAuth: FC<HeaderAuthProps> = ({
  className,
  isSignIn,
  toggleSignIn
}): ReactElement => {
  const { i18n } = useLingui()

  return (
    <div className={classNames(styles.container, className)}>
      <Link href="/login" className={styles.logoAcqua}>
        <button type="button" aria-label="Moises">
          <img
            alt=""
            width="36"
            height="23"
            src="//storage.googleapis.com/scala-web/assets/images/moises-acqua.svg"
          />
        </button>
      </Link>

      <Link href="/login">
        <button type="button" className={styles.logo} aria-label="Moises">
          <img
            alt=""
            width="127"
            height="34"
            src="//storage.googleapis.com/scala-web/assets/images/moises-logo-white.svg"
          />
        </button>
      </Link>

      <div className={styles.actions}>
        {isSignIn ? (
          <>
            <button
              type="button"
              onClick={toggleSignIn}
              id="sign_up_button_mobile"
              className={styles.buttonMobile}
            >
              <p className={styles.label}>{i18n._(t`auth.header.label`)}</p>
              <p className={styles.highlight}>
                {i18n._(t`actions.signup.free`)}
              </p>
            </button>

            <div className={styles.actionsDesktop}>
              <p className={styles.label}>{i18n._(t`auth.header.label`)}</p>
              <Button
                small
                radius={1}
                id="sign_up_button"
                onClick={toggleSignIn}
                title={i18n._(t`actions.signup.free`)}
                className={styles.buttonDesktop}
              />
            </div>
          </>
        ) : (
          <>
            <button
              type="button"
              id="sign_in_button_mobile"
              onClick={toggleSignIn}
              className={styles.buttonMobile}
            >
              <p className={styles.label}>
                {i18n._(t`auth.header.label.member`)}
              </p>
              <p className={styles.highlight}>{i18n._(t`actions.login`)}</p>
            </button>

            <div className={styles.actionsDesktop}>
              <p className={styles.label}>
                {i18n._(t`auth.header.label.member`)}
              </p>
              <Button
                small
                radius={1}
                id="sign_in_button"
                title={i18n._(t`actions.login`)}
                onClick={toggleSignIn}
                className={styles.buttonDesktop}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { useEffect } from 'react'
import { useToastSlowerProcessing } from '../../hooks/user/use-toast-slower-processing'
import { useSetUtmStorage } from '../../hooks/analytics/use-utm-storage/use-set-utm-storage'
import styles from './page-wrapper.module.scss'

interface PageWrapperProps {
  className?: string
  catalogs: any
  locale: any
  fullScreen?: boolean
  children: React.ReactNode
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  className,
  catalogs,
  locale,
  children,
  fullScreen
}) => {
  const { i18n } = useLingui()
  useSetUtmStorage()
  useToastSlowerProcessing()

  useEffect(() => {
    if (i18n.locale !== locale) {
      i18n.load({
        [locale]: catalogs[locale]
      })

      i18n.activate(locale)
    }
  }, [locale, i18n, catalogs])

  return (
    <div
      className={classnames(
        className,
        styles.container,
        fullScreen && styles.fullScreen
      )}
    >
      {children}
    </div>
  )
}

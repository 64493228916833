import { useCallback, useState } from 'react'

interface SignInProviders {
  email: string
  provider: string
  lastSignIn: number
}

export interface UseSignInProviders {
  show: boolean
  providers: SignInProviders[]
  onOpen: () => void
  onClose: () => void
  registerProvider: ({
    provider,
    email
  }: {
    provider: string
    email: string
  }) => void
  checkIfExistsLastLoginProvider: () => void
}

const STORAGE_LOGIN_PROVIDER = 'moises:login:providers'

const mapProviders: { [key: string]: string } = {
  'apple.com': 'apple',
  'google.com': 'google',
  'twitter.com': 'twitter',
  'facebook.com': 'facebook',
  password: 'email'
}

export const useSignInProviders = (): UseSignInProviders => {
  const [show, setShow] = useState(false)
  const [providers, setProviders] = useState<SignInProviders[]>([])

  const onOpen = useCallback(() => setShow(true), [])
  const onClose = useCallback(() => setShow(false), [])

  const registerProvider = useCallback<UseSignInProviders['registerProvider']>(
    ({ provider: userProvider, email }) => {
      const provider = mapProviders[userProvider]

      if (!provider || !email) return

      const storage = localStorage.getItem(STORAGE_LOGIN_PROVIDER) || '[]'
      const listProviders: SignInProviders[] = JSON.parse(storage)
      const providerIndex = listProviders.findIndex((p) => p?.email === email)

      if (providerIndex === -1) {
        listProviders.unshift({ email, provider, lastSignIn: Date.now() })
      } else {
        listProviders[providerIndex] = {
          email,
          provider,
          lastSignIn: Date.now()
        }
      }

      localStorage.setItem(
        STORAGE_LOGIN_PROVIDER,
        JSON.stringify(listProviders.slice(0, 3))
      )
    },
    []
  )

  const checkIfExistsLastLoginProvider = useCallback<
    UseSignInProviders['checkIfExistsLastLoginProvider']
  >(() => {
    const storage = localStorage.getItem(STORAGE_LOGIN_PROVIDER) || '[]'
    const listProviders: SignInProviders[] = JSON.parse(storage)

    if (listProviders.length) {
      setProviders(listProviders)
      onOpen()
    }
  }, [onOpen])

  return {
    show,
    providers,
    onOpen,
    onClose,
    registerProvider,
    checkIfExistsLastLoginProvider
  }
}

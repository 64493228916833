import { useRouter } from 'next/router'
import { pick } from 'ramda'
import { useCallback, useEffect } from 'react'
import { key } from '.'

interface UseSetUtmStorage {
  setUtmOnSession(params: UtmParams): void
}

interface UtmParams {
  utm_source?: string // eslint-disable-line camelcase
  utm_medium?: string // eslint-disable-line camelcase
  utm_content?: string // eslint-disable-line camelcase
  utm_campaign?: string // eslint-disable-line camelcase
  utm_term?: string // eslint-disable-line camelcase
}

const getParameters = (params: UtmParams): UtmParams =>
  pick(
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'],
    params
  )

const setStorage = (
  trackingParameters: UtmParams,
  isSession?: boolean
): void => {
  if (!Object.keys(trackingParameters).length) {
    return
  }

  if (isSession) {
    sessionStorage.setItem(key, JSON.stringify(trackingParameters))
  } else {
    localStorage.setItem(key, JSON.stringify(trackingParameters))
  }
}

export const useSetUtmStorage = (): UseSetUtmStorage => {
  const { query } = useRouter()

  const setUtmOnSession = useCallback(
    (params: UtmParams) => setStorage(getParameters(params), true),
    []
  )

  useEffect(() => {
    setStorage(getParameters(query as any))
  }, [query])

  return {
    setUtmOnSession
  }
}

import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useIsEmailValid } from '../../misc/use-is-email-valid'
import { ContextShell } from '../../../modules/context'
import {
  appleIdSignIn,
  emailPasswordSignIn,
  emailPasswordSignUp,
  facebookSignIn,
  googleSignIn,
  signOut,
  twitterSignIn
} from './providers'
import {
  UseSignInProviders,
  useSignInProviders
} from '../use-sign-in-providers'

interface UseAuthentication {
  email: string
  password: string
  error: string | null
  loading: boolean
  errorEmail: boolean
  errorPassword: boolean
  signInProviders: UseSignInProviders
  onFocusEmail(): void
  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void
  onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void
  signInEmail(event: React.FormEvent<HTMLFormElement>): void
  signUpEmail(event: React.FormEvent<HTMLFormElement>): void
  signInFacebook(): void
  signInGoogle(): void
  signInApple(): void
  signInTwitter(): void
  signOut(): Promise<void>
  onBlur(): void
}

interface UseAuthProps {
  isSignIn?: boolean
}

export const useAuthentication = ({
  isSignIn
}: UseAuthProps): UseAuthentication => {
  const { i18n } = useLingui()
  const router = useRouter()
  const {
    toast: { add: addToast }
  } = useContext(ContextShell)
  const signInProviders = useSignInProviders()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const isValidEmail = useIsEmailValid(email)

  const resetErrors = useCallback(() => {
    setError(null)
    setErrorEmail(false)
    setErrorPassword(false)
  }, [])

  const onFocusEmail = useCallback(() => {
    signInProviders.checkIfExistsLastLoginProvider()
  }, [signInProviders])

  const onChangeEmail = useCallback(
    ({ target: { value } }: any) => {
      setEmail(value.trim())
      resetErrors()
      signInProviders.onClose()
    },
    [resetErrors, signInProviders]
  )

  const onChangePassword = useCallback(
    ({ target: { value } }: any) => {
      setPassword(value)
      resetErrors()
    },
    [resetErrors]
  )

  const attemptToSignIn = useCallback(
    async (fn: () => void) => {
      try {
        setLoading(true)
        resetErrors()

        const dataLogin: any = await fn()
        signInProviders.registerProvider({
          email: dataLogin?.user?.email || email,
          provider: dataLogin?.additionalUserInfo?.providerId
        })
      } catch (err: any) {
        if (
          err.code === 'auth/user-not-found' ||
          err.code === 'auth/wrong-password' ||
          err.code === 'auth/email-already-in-use'
        ) {
          const message =
            err.code === 'auth/wrong-password' ||
            err.code === 'auth/user-not-found'
              ? i18n._(t`auth.signin.error`)
              : err.code === 'auth/email-already-in-use'
              ? i18n._(t`change.email.label.duplicate`)
              : ''

          setErrorEmail(true)
          setErrorPassword(true)
          setError(message)
        } else {
          const messageFirebase =
            err.code === 'auth/popup-closed-by-user'
              ? i18n._(t`auth.signup.error`)
              : err.code === 'auth/operation-not-allowed'
              ? i18n._(t`ifLoginProvider`)
              : err.message

          addToast({
            type: 'error',
            closable: true,
            description: messageFirebase,
            icon: null
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [i18n, email, addToast, resetErrors, signInProviders]
  )

  const validateEmail = useCallback(() => {
    if (!isValidEmail || !email.length) {
      setErrorEmail(true)
      setError(i18n._(t`change.email.input.invalid.email`))
      return false
    }

    return true
  }, [i18n, email, isValidEmail])

  const validatePassword = useCallback(() => {
    if (!password.length) {
      setErrorPassword(true)
      setError(i18n._(t`change.email.input.invalid.password`))
      return false
    }
    if (!isSignIn && password.length < 6) {
      setErrorPassword(true)
      setError(i18n._(t`auth.signup.password.description`))

      return false
    }

    return true
  }, [i18n, password, isSignIn])

  const handleBlurForm = useCallback(() => {
    if (email.length) {
      validateEmail()
    }

    if (password.length) {
      validatePassword()
    }
  }, [email, password, validateEmail, validatePassword])

  const handleSignInEmail = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      resetErrors()

      if (!validateEmail() || !validatePassword()) {
        return
      }

      attemptToSignIn(() => emailPasswordSignIn(email, password))
    },
    [
      email,
      password,
      attemptToSignIn,
      resetErrors,
      validatePassword,
      validateEmail
    ]
  )

  const handleSignUpEmail = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      resetErrors()

      if (!validateEmail() || !validatePassword()) {
        return
      }

      attemptToSignIn(async () => {
        const { user } = await emailPasswordSignUp(email, password)
        await user.sendEmailVerification()
      })
    },
    [
      email,
      password,
      attemptToSignIn,
      resetErrors,
      validatePassword,
      validateEmail
    ]
  )

  useEffect(() => {
    const { email: emailVal } = router.query

    if (emailVal) {
      setEmail(`${emailVal}`)
    }
  }, [router])

  return {
    email,
    password,
    error,
    loading,
    errorEmail,
    errorPassword,
    signInProviders,
    onFocusEmail,
    onChangeEmail,
    onChangePassword,
    onBlur: handleBlurForm,
    signOut,
    signInEmail: handleSignInEmail,
    signUpEmail: handleSignUpEmail,
    signInFacebook: () => attemptToSignIn(() => facebookSignIn()),
    signInGoogle: () => attemptToSignIn(() => googleSignIn()),
    signInApple: () => attemptToSignIn(() => appleIdSignIn()),
    signInTwitter: () => attemptToSignIn(() => twitterSignIn())
  }
}

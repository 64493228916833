import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { ReactElement, useMemo } from 'react'
import { FormInput } from '../../form/form-input'
import { Icon } from '../../icon'
import { Button } from '../../button'
import styles from './email-password-sign-in.module.scss'

interface EmailPasswordSignInProps {
  email: string
  password: string
  error: string | null
  loading: boolean
  isSignIn: boolean
  isReferral: boolean
  errorEmail: boolean
  errorPassword: boolean
  onBlur(): void
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onFocusEmail(): void
  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void
  onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void
}

export const EmailPasswordSignIn = ({
  error,
  loading,
  email,
  password,
  isSignIn,
  isReferral,
  errorEmail,
  errorPassword,
  onBlur,
  onSubmit,
  onFocusEmail,
  onChangeEmail,
  onChangePassword
}: EmailPasswordSignInProps): ReactElement => {
  const { i18n } = useLingui()

  const attsEmail = useMemo(
    () => ({
      spellCheck: 'false',
      autoCapitalize: 'none'
    }),
    []
  )

  const attsPassword = useMemo(
    () => ({
      spellCheck: 'false',
      autoCapitalize: 'none',
      autoComplete: !isSignIn ? 'new-password' : 'password'
    }),
    [isSignIn]
  )

  const buttonLabel = useMemo(() => {
    if (loading) {
      return isSignIn ? t`label.loading` : t`actions.signingup`
    }

    if (isReferral) {
      return t`refer_friend_activate`
    }

    return isSignIn ? t`actions.login` : t`actions.signup.free`
  }, [isReferral, isSignIn, loading])

  return (
    <div className={styles.container}>
      <form onSubmit={onSubmit} onBlur={onBlur}>
        <FormInput
          value={email}
          error={errorEmail}
          attributes={attsEmail}
          className={styles.input}
          onChange={onChangeEmail}
          onFocus={onFocusEmail}
          id="email_address_textbox"
          placeholder={i18n._(t`auth.email.placeholder`)}
          icon={<Icon name="email" className={styles.icon} />}
        />

        <FormInput
          type="password"
          value={password}
          error={errorPassword}
          id="password_textbox"
          attributes={attsPassword}
          className={styles.input}
          onChange={onChangePassword}
          icon={<Icon name="lock" className={styles.icon} />}
          placeholder={
            isSignIn
              ? i18n._(t`label.password`)
              : i18n._(t`auth.signup.password.placeholder`)
          }
        >
          {error ? (
            <p className={styles.labelError}>{error}</p>
          ) : !isSignIn ? (
            <p
              className={classnames(styles.label, {
                [styles.labelError]: errorPassword
              })}
            >
              {i18n._(t`auth.signup.password.description`)}
            </p>
          ) : null}
        </FormInput>

        {!isSignIn ? (
          <p className={styles.labelTerms}>
            <span // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18n
                  ._(t`auth.signup.agree`)
                  .replace(
                    /\*([^*]+)\*/,
                    `<a id="terms_link" target="_blank" href="/terms">$1</a>`
                  )
                  .replace(
                    /\*([^*]+)\*/,
                    `<a id="privacy_link" target="_blank" href="/privacy">$1</a>`
                  )
              }}
            />
          </p>
        ) : null}

        <Button
          small
          radius={1}
          type="submit"
          className={styles.button}
          disabled={loading}
          id={isSignIn ? 'login_button' : 'signup_button'}
          icon={
            loading ? (
              <Icon name="loader" animation="spin" />
            ) : (
              <Icon name="arrow" className={styles.iconSignIn} />
            )
          }
          title={buttonLabel}
        />
      </form>
    </div>
  )
}

import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useCallback, useMemo } from 'react'
import { UseSignInProviders } from '../../../hooks/auth/use-sign-in-providers'
import { Icon } from '../../icon'
import styles from './styles.module.scss'

interface Props extends UseSignInProviders {
  signInApple(): void
  signInGoogle(): void
  signInTwitter(): void
  signInFacebook(): void
  onChangeEmail({ target: { value } }: { target: { value: string } }): void
}

const iconMap: { [key: string]: string } = {
  apple: 'social-apple',
  google: 'social-google',
  twitter: 'social-x',
  facebook: 'social-facebook',
  email: 'email'
}

export const SignInProviders: React.FC<Props> = ({
  providers,
  onClose,
  signInApple,
  signInGoogle,
  signInTwitter,
  signInFacebook,
  onChangeEmail
}) => {
  const { i18n } = useLingui()

  const handleProvider = useCallback(
    ({ provider, email }: { provider: string; email: string }) => {
      const providerSignInFunctions: {
        [key: string]: () => void
      } = {
        apple: signInApple,
        google: signInGoogle,
        twitter: signInTwitter,
        facebook: signInFacebook,
        email: () => onChangeEmail({ target: { value: email as string } })
      }
      const signInFunction = providerSignInFunctions[provider]

      signInFunction()
      onClose()
    },
    [
      onClose,
      signInApple,
      signInGoogle,
      signInTwitter,
      signInFacebook,
      onChangeEmail
    ]
  )

  const textMap: { [key: string]: string } = useMemo(
    () => ({
      apple: i18n._(t`status_sign_in_via_apple`),
      google: i18n._(t`status_sign_in_via_google`),
      twitter: i18n._(t`status_sign_in_via_twitter`),
      facebook: i18n._(t`status_sign_in_via_facebook`)
    }),
    [i18n]
  )

  const list = useMemo(() => {
    return providers.map(({ provider, email, lastSignIn }) => ({
      email,
      provider,
      text:
        provider === 'email'
          ? `${i18n._(t`status_sign_in_via_email`)} ${email}`
          : textMap[provider],
      lastSignIn: lastSignIn
        ? new Date(lastSignIn).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit'
          })
        : null,
      onClick: () => handleProvider({ provider, email })
    }))
  }, [i18n, textMap, providers, handleProvider])

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {providers.length === 1
          ? i18n._(t`dropdown_last_sign_in_singular`)
          : i18n._(t`dropdown_last_sign_in_plural`)}
      </p>

      <div className={styles.providers}>
        {list.map(({ provider, email, text, lastSignIn, onClick }) => (
          <button
            key={`${provider}-${email}-${lastSignIn}`}
            type="button"
            onClick={onClick}
            className={styles.button}
          >
            <Icon name={iconMap[provider]} width={20} height={20} />

            <span className={styles.text}>
              {text}

              {lastSignIn && (
                <span className={styles.date}>({lastSignIn})</span>
              )}
            </span>

            <Icon
              name="arrow"
              width={20}
              height={20}
              className={styles.iconArrow}
            />
          </button>
        ))}
      </div>
    </div>
  )
}
